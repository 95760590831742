import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ErrorPageScreen from '@/components/error/ErrorPageScreen'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingScreen from '@/components/LoadingScreen'
import Spacer from '@/components/Spacer'
import BankLogo from '@/components/ui/BankLogo'
import Title from '@/components/ui/Title'
import { useLocale } from '@/contexts/localeContext'
import usePage from '@/hooks/usePage'
import { tt } from '@/locales/format'
import { SubTitle } from '@/pages//profile/Shared'
import {
  eDDAAccountSelector,
  eDDAInitedSelector,
} from '@/redux/profile/profileSelector'
import * as BankUtils from '@/utils/Bank'
import * as eDDAUtils from '@/utils/eDDA'
import { FormattedPrice } from '@/utils/Price'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const ItemTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const ItemValue = styled.div`
  font-size: 1rem;
`

const TextButton = styled.div`
  text-decoration: underline;
  font-size: 0.778rem;
  font-weight: 700;
  cursor: pointer;
`

const AccountText = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const StatusBox = styled.div`
  position: relative;
  padding: 1.111rem;
  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 0.444rem;
`

const StatusBoxInner = styled.div`
  opacity: ${(p) => (p.isBlur ? 0.5 : 1)};
  display: flex;
`

const AccountInfo = styled.div``

const ProcessingStatusLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #048bf1;
  border-radius: 0.222rem;
  color: white;
  font-size: 0.667rem;
  padding: 4px 10px;
`

const VoidedStatusLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff0000;
  border-radius: 0.222rem;
  color: white;
  font-size: 0.667rem;
  padding: 4px 10px;
`

const EDDAConfirmation = () => {
  const intl = useIntl()
  const history = useHistory()
  const { currentLocale } = useLocale()

  const isUserEDDAInited = useSelector(eDDAInitedSelector)
  const profileEDDA = useSelector(eDDAAccountSelector)

  const { retry, isInitLoading, isInitError } = usePage({
    initAction: { type: 'pageInit/initProfileEDDA' },
  })

  useEffect(() => {
    if (!isUserEDDAInited) {
      history.replace('/profile')
    }
  }, [])

  if (isInitLoading) return <LoadingScreen />
  if (isInitError) return <ErrorPageScreen onRetry={retry} />

  const onClickBack = () => {
    history.push('/profile')
  }

  const onClickGoSetting = () => {
    history.push('/edda/settings')
  }

  const isActivated = eDDAUtils.isActivated(profileEDDA)
  const isProcessing = eDDAUtils.isRequested(profileEDDA)
  const isFailed = eDDAUtils.isFailed(profileEDDA)

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <PaddingContent>
          <Spacer height="2.222rem" />
          <Title onClickBack={onClickBack}>{tt(intl, 'edda.title')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>{tt(intl, 'edda.subtitle')}</SubTitle>
          <Spacer height="2.222rem" />
          <StatusBox isBlur={!isActivated}>
            <StatusBoxInner isBlur={!isActivated}>
              <div>
                <BankLogo
                  width="3.111rem"
                  height="3.111rem"
                  bankCode={profileEDDA?.bankCode}
                />
              </div>
              <Spacer width="0.889rem" />
              <AccountInfo>
                <AccountText>{profileEDDA?.holderName}</AccountText>
                <Spacer height="0.444rem" />
                <AccountText>
                  {BankUtils.getBankString(
                    profileEDDA?.bankCode,
                    currentLocale
                  )}
                </AccountText>
                <Spacer height="0.444rem" />
                <AccountText>{profileEDDA?.accountNum}</AccountText>
              </AccountInfo>
            </StatusBoxInner>
            {isProcessing && (
              <ProcessingStatusLabel>
                {tt(intl, 'common.processing')}
              </ProcessingStatusLabel>
            )}
            {isFailed && (
              <VoidedStatusLabel>{tt(intl, 'common.voided')}</VoidedStatusLabel>
            )}
          </StatusBox>
          <Spacer height="1.778rem" />
          {isActivated && (
            <>
              <Row>
                <ItemTitle>{tt(intl, 'edda.dailytransactionlimit')}</ItemTitle>
                <TextButton onClick={onClickGoSetting}>
                  {tt(intl, 'common.edit')}
                </TextButton>
              </Row>
              <Spacer height="0.778rem" />
              <ItemValue>
                {FormattedPrice(
                  profileEDDA.userMaxLimit.currency,
                  profileEDDA.userMaxLimit.amount,
                  false
                )}
              </ItemValue>
              <Spacer height="1.333rem" />
              <Line />
              <Spacer height="1.333rem" />
            </>
          )}
          <Spacer height="2.222rem" />
        </PaddingContent>
      </CSLayout.CSContent>
    </CSLayout.CSContainer>
  )
}

export default EDDAConfirmation
