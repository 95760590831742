// eDDA object status: requested, pending, active, voided

export const isActivated = (eDDA) => eDDA?.status === 'active'

export const isRequested = (eDDA) =>
  eDDA?.status === 'pending' || eDDA?.status === 'requested'

export const isInited = (eDDA) => isActivated(eDDA) || isRequested(eDDA)

export const isFailed = (eDDA) => eDDA?.status === 'voided'

export const isVoided = (eDDA) => eDDA?.status === 'voided'

export default null
