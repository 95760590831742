import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as CloseSvg } from '@/assets/paynow/guide-close.svg'
import BottomSheet from '@/components/BottomSheet'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import { tt } from '@/locales/format'
import { Button } from '@/pages/Auth/shared/style'

import RightArrowIcon from './icons/RightArrow'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
  box-sizing: border-box;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -0.7rem;
`

const Title = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
`

const BankList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`

const BankItem = styled.div`
  text-align: center;
`

const BankLogo = styled.img`
  border-radius: 0.625rem;
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.4rem;
`

const BankName = styled.div`
  font-size: 11px;
  font-weight: 600;
`

const MoreContents = styled.div`
  width: 100%;
  display: flex;
  font-size: 16px;
  gap: 8px;
  font-weight: bold;
  color: #fc3;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const W2APaymentSelectionModal = ({
  generateFpsLink,
  controls,
  requestCode,
  isOpen,
  onClose,
}) => {
  const intl = useIntl()

  const chunkArray = (array, chunkSize) => {
    const chunkedArray = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize))
    }
    return chunkedArray
  }

  const chunkedArray = useMemo(() => chunkArray(controls, 4), [controls])

  const goPath = (control) => () => {
    const payReqObj = encodeURIComponent(requestCode)
    if (process.env.REACT_APP_API_PLATFORM === 'production') {
      window.location.href = `${control.link}?pay_req_obj=${payReqObj}`
    } else if (
      window.confirm(
        'this payment is created for testing purpose only, do not pay with your bank app!! if you want to continue the test of the web-to-app function, click OK to proceed.'
      )
    ) {
      window.location.href = `${control.link}?pay_req_obj=${payReqObj}`
    }
    onClose()
  }

  const onClickActionButton = () => {
    if (process.env.REACT_APP_API_PLATFORM === 'production') {
      window.location.href = generateFpsLink.data?.web2AppPaymentGatewayLink
    } else if (
      window.confirm(
        'this payment is created for testing purpose only, do not pay with your bank app!! if you want to continue the test of the web-to-app function, click OK to proceed.'
      )
    ) {
      window.location.href = generateFpsLink.data?.web2AppPaymentGatewayLink
    }
    onClose()
  }

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <Content>
        <Spacer height="1.5rem" />
        <ContentHeader>
          <Title>{tt(intl, 'banklist.payByBankApp')}</Title>
          <Icon onClick={onClose} renderImage={() => <CloseSvg />} />
        </ContentHeader>
        <Spacer height="1.5rem" />
        {chunkedArray.length > 0 &&
          chunkedArray.map((chunk, index) => (
            <BankList key={index}>
              {chunk.map((item) => (
                <BankItem key={item.id} onClick={goPath(item)}>
                  <BankLogo src={item.icon} />
                  <BankName>{tt(intl, item.name)}</BankName>
                </BankItem>
              ))}
              <Spacer height="1rem" />
            </BankList>
          ))}

        <Spacer height="0.5rem" />
        <MoreContents onClick={onClickActionButton}>
          {tt(intl, 'banklist.moreBanks')}{' '}
          <Icon
            renderImage={() => <RightArrowIcon />}
            width="1.111rem"
            height="1.111rem"
          />
        </MoreContents>
        <Spacer height="1.5rem" />
      </Content>
    </BottomSheet>
  )
}

export default W2APaymentSelectionModal
