import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as QuestionSvg } from '@/assets/payment/fps-howto.svg'
import CountDownLabel from '@/components/CountDownLabel'
import PaddingContent from '@/components/layout/PaddingContent'
import Spacer from '@/components/Spacer'
import PaymentSettings from '@/constants/payment'
import useWindowSize from '@/hooks/useWindowSize'
import { tt } from '@/locales/format'

import Icon from '../Icon'
import ArrowDownIcon from '../icons/ArrowDown'
import W2APaymentSelectionModal from '../W2APaymentSelectionModal'
import { ListItemRow } from './FpsPaymentStyles'

const MobileContainer = styled.div`
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MobileListCont = styled.div`
  width: 100%;
  background-color: #fff7e8;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const MobileListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const MobileListTitles = styled.div`
  font-weight: 700;
`

const NewBtn = styled.button`
  background-color: ${({ secondary, theme }) =>
    secondary ? theme.button.secondary : theme.button.active};
  font-size: ${({ size }) => size || '1rem'};
  letter-spacing: 0.2px;
  width: auto;
  padding: 0 1rem;
  border-radius: 1.25rem;
  font-size: 0.725rem;
  font-weight: 700;
  z-index: 2;
`

const BankList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: non-wrap;
`

const BankItem = styled.div`
  text-align: center;
`

const BankLogo = styled.img`
  border-radius: 0.625rem;
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.4rem;
`

const BankName = styled.div`
  font-size: 11px;
  font-weight: 600;
`

const MoreContents = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
`

const DesktopContainer = styled(PaddingContent)`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding-left: 1.2rem;
  padding-right: 0rem;
  justify-content: space-between;
`

const DesktopContainerLeft = styled.div`
  text-align: left;
  flex: 1;
`

const DesktopContainerRight = styled.div`
  text-align: right;
`

const PaymentMethodTitle = styled.div`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  padding-top: 1.2rem;
  word-wrap: wrap;
`

const QRRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin: 4px;
`

const FpsUserGuideTitle = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.8rem 1.2rem;
  border: 1px solid #eff0f1;
  border-radius: 0.8rem;
`

const HowToPay = styled(ListItemRow)`
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  justify-content: left;
  align-items: center;
  margin-left: 0.3rem;
`
const QRImg = styled.img``

const Instruction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.8rem;
`

const InstructionNum = styled.div`
  border-radius: 50%;
  background-color: #fff7e8;
  font-size: 0.8rem;
  font-weight: 700;
  color: #ffc20d;
  margin-right: 1rem;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InstructionText = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
`

const FpsQRCode = ({
  containerRef,
  qrCodeRef,
  payLink,
  qrCodeSize,
  onClick,
}) => (
  <QRContainer ref={containerRef} onClick={onClick}>
    <QRImg
      ref={qrCodeRef}
      src={payLink}
      width={qrCodeSize}
      alt="fps-qr"
      style={{ width: '100%' }}
    />
  </QRContainer>
)

const FpsPaymentDetailsDesktop = ({ fpsDetails, showGuide }) => {
  const intl = useIntl()
  const windowSize = useWindowSize()
  const paidby = fpsDetails.paidCurrency

  const { webLink: payLink = '' } = fpsDetails || {}

  const qrContainerRef = useRef()
  const qrCodeRef = useRef()

  const downloadQR = async () => {
    const element = document.createElement('a')
    element.href = qrCodeRef.current.src
    element.download = `divit-qrcode-${Date.now()}.jpg`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const downloadQRCode = (e) => {
    e.preventDefault()
    if (!isMobile) downloadQR()
  }

  const qrCodeSize = 255 // windowSize.width >= 425 ? 255 : 200

  if (!windowSize.width) return null

  return (
    <>
      <DesktopContainer>
        <DesktopContainerLeft>
          <PaymentMethodTitle>
            {tt(intl, 'fps.paytosecureorder', {
              countdown: (
                <>
                  &nbsp;
                  <CountDownLabel timeoutInSec={fpsDetails.effectiveDuration} />
                </>
              ),
            })}
          </PaymentMethodTitle>
          <Instruction>
            <InstructionNum>1</InstructionNum>
            <InstructionText>
              {tt(
                intl,
                `${
                  PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[
                    fpsDetails.paidCurrency
                  ]
                }.qrcode.instruction.1`
              )}
            </InstructionText>
          </Instruction>
          <Spacer height="2.4rem" />
          <FpsUserGuideTitle onClick={showGuide}>
            {tt(
              intl,
              `${
                PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[
                  fpsDetails.paidCurrency
                ]
              }.userguide`
            )}
            <HowToPay>
              <QuestionSvg />
            </HowToPay>
          </FpsUserGuideTitle>
        </DesktopContainerLeft>
        <DesktopContainerRight>
          <QRRow>
            <FpsQRCode
              qrCodeRef={qrCodeRef}
              containerRef={qrContainerRef}
              payLink={payLink}
              qrCodeSize={qrCodeSize}
              onClick={downloadQRCode}
            />
          </QRRow>
        </DesktopContainerRight>
      </DesktopContainer>
    </>
  )
}

const FpsPaymentDetailsMobile = ({
  generateFpsLink,
  fpsDetails,
  showGuide,
  bankListFps,
  isPosMode = false,
}) => {
  const intl = useIntl()
  const isSupportW2A =
    (fpsDetails?.paidCurrency || '') === PaymentSettings.CURRENCY_HKD
  const windowSize = useWindowSize()

  const { webLink: payLink = '', web2AppRequestObjLink = '' } = fpsDetails || {}

  const qrContainerRef = useRef()
  const qrCodeRef = useRef()
  const [isShowW2APaymentSelectionModal, setIsShowW2APaymentSelectionModal] =
    useState(false)

  const downloadQR = async () => {
    const a = document.createElement('a')
    a.href = qrCodeRef.current.src
    a.download = `divit-qrcode-${Date.now()}.png`
    a.click()
  }

  const downloadQRCode = (e) => {
    e.preventDefault()
    if (!isMobile) downloadQR()
  }

  const handleShowW2APaymentSelectionModal = () => {
    setIsShowW2APaymentSelectionModal(true)
  }

  const goPath = (control) => () => {
    const payReqObj = encodeURIComponent(web2AppRequestObjLink)
    if (process.env.REACT_APP_API_PLATFORM === 'production') {
      window.location.href = `${control.link}?pay_req_obj=${payReqObj}`
    } else if (
      window.confirm(
        'the payment is created for testing purpose only, do not pay with your bank app!! if you want to continue the test of the web-to-app function, you can click OK to proceed.'
      )
    ) {
      window.location.href = `${control.link}?pay_req_obj=${payReqObj}`
    }
  }

  const qrCodeSize = 320 // windowSize.width >= 425 ? 200 : 200

  const bankListDisplay = useMemo(() => bankListFps.slice(0, 4), [bankListFps])

  if (!windowSize.width) return null

  return (
    <>
      {!isPosMode && isSupportW2A && (
        <MobileListCont>
          <MobileListHeader>
            <MobileListTitles>
              {tt(intl, 'banklist.payByBankApp')}
            </MobileListTitles>
            <NewBtn type="auto" data-selenium="new">
              {tt(intl, 'banklist.new').toUpperCase()}
            </NewBtn>
          </MobileListHeader>
          <Spacer height="1.5rem" />

          <BankList>
            {bankListDisplay.map((item) => (
              <BankItem key={item.id} onClick={goPath(item)}>
                <BankLogo src={item.icon} />
                <BankName>{tt(intl, item.name)}</BankName>
              </BankItem>
            ))}
          </BankList>
          <Spacer height="1.5rem" />
          <MoreContents onClick={handleShowW2APaymentSelectionModal}>
            {tt(intl, 'banklist.moreBanks')}{' '}
            <Icon
              renderImage={() => <ArrowDownIcon color="#000" />}
              width="1.111rem"
              height="1.111rem"
            />
          </MoreContents>
        </MobileListCont>
      )}
      <MobileContainer>
        <PaymentMethodTitle>
          {tt(intl, 'fps.paytosecureorder', {
            countdown: (
              <>
                &nbsp;
                <CountDownLabel timeoutInSec={fpsDetails.effectiveDuration} />
              </>
            ),
          })}
        </PaymentMethodTitle>
        <QRRow>
          <FpsQRCode
            qrCodeRef={qrCodeRef}
            containerRef={qrContainerRef}
            payLink={payLink}
            qrCodeSize={qrCodeSize}
            onClick={downloadQRCode}
          />
        </QRRow>
        <FpsUserGuideTitle onClick={showGuide}>
          {tt(
            intl,
            `${
              PaymentSettings.CURRENCY_TO_PAYMENT_METHOD[
                fpsDetails.paidCurrency
              ]
            }.userguide`
          )}
          <HowToPay>
            <QuestionSvg />
          </HowToPay>
        </FpsUserGuideTitle>

        <W2APaymentSelectionModal
          generateFpsLink={generateFpsLink}
          controls={bankListFps}
          requestCode={web2AppRequestObjLink}
          isOpen={isShowW2APaymentSelectionModal}
          onClose={() => setIsShowW2APaymentSelectionModal(false)}
        />
      </MobileContainer>
    </>
  )
}

const FpsPaymentDetails = ({
  generateFpsLink,
  fpsDetails,
  bankListFps,
  showGuide,
  isPosMode = false,
}) => {
  const { width } = useWindowSize()
  const isDesktop = width >= 640

  return isDesktop ? (
    <FpsPaymentDetailsDesktop fpsDetails={fpsDetails} showGuide={showGuide} />
  ) : (
    <FpsPaymentDetailsMobile
      generateFpsLink={generateFpsLink}
      fpsDetails={fpsDetails}
      showGuide={showGuide}
      bankListFps={bankListFps}
      isPosMode={isPosMode}
    />
  )
}

export default FpsPaymentDetails
